import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CardElement, useElements } from '@stripe/react-stripe-js'
import { omit, isEmpty } from 'lodash'

import SubscriptionTypeField from './SubscriptionTypeField'
import { checkPromoCode } from '../helpers/stripe'
import { submitButtonStyle } from '../styles'

function validate(values, promoCodeValid) {
  const errors = {}

  if (!values.subscriptionType) {
    errors.subscriptionType = "Please choose a subscription type"
  }
  if (values.promoCode && !promoCodeValid) {
    errors.promoCode = "Please enter a valid promo code"
  }

  return errors
}


function CheckoutForm({ onSubmit }) {
  const elements = useElements()
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [promoCodeValid, setPromoCodeValid] = React.useState(false)

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))

    if (key === 'subscriptionType' || key === 'promoCode') {
      let subscriptionType = key === 'subscriptionType' ? value : values.subscriptionType
      let promoCode = key === 'promoCode' ? value : values.promoCode
      handleCheckPromoCode(subscriptionType, promoCode)
    }
  }

  const getPromoCodeText = () => {
    if (values.subscriptionType === 'monthly' && values.promoCode && promoCodeValid) {
      return "Promo code valid for 50% off your first month!"
    } else if (values.subscriptionType === 'annual' && values.promoCode && promoCodeValid) {
      return "Promo code valid for 10% off your first year!"
    } else {
      return null
    }
  }

  const handleCheckPromoCode = async (subscriptionType, promoCode) => {
    if (subscriptionType && promoCode) {
      try {
        await checkPromoCode(subscriptionType, promoCode)
        setPromoCodeValid(true)
      } catch (error) {
        setPromoCodeValid(false)
      }
    } else {
      setPromoCodeValid(false)
    }
  }

  const handleShowPromoCodeError = () => {
    if (values.subscriptionType && values.promoCode && !promoCodeValid) {
      setErrors({ promoCode: "Invalid promo code" })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values, promoCodeValid)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await onSubmit(values, elements.getElement(CardElement))
        setSent(false)
      } catch (error) {
        setSent(false)
        console.log(error.code, error.message)
        if (error.code === "invalid-promo-code") {
          setErrors({ promoCode: error.message })
        } else {
          setErrors({ cardElement: error.message })
        }
      }
    }
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 6 }}>
      <SubscriptionTypeField
        value={values.subscriptionType}
        onChange={value => setValue('subscriptionType', value)}
        error={!!errors.subscriptionType}
        helperText={errors.subscriptionType} />

      <Box sx={{ mt: 6 }}>
        <CardElement onChange={() => setErrors({ cardElement: null })} />
      </Box>

      {errors.cardElement ?
        <Typography component="p" variant="helperText">
          {errors.cardElement}
        </Typography> : null}

      <TextField
        name="promo-code"
        label="Promo Code"
        margin="normal"
        variant="standard"
        autoComplete="promo-code"
        disabled={sent}
        value={values.promoCode || ''}
        onChange={e => setValue('promoCode', e.target.value)}
        onBlur={handleShowPromoCodeError}
        error={!!errors.promoCode}
        helperText={errors.promoCode || getPromoCodeText()}
        FormHelperTextProps={{ className: promoCodeValid ? 'helper-text-success' : '' }}
        style={{ width: '170px' }}
      />

      <Button
        fullWidth
        type="submit"
        color="primary"
        disabled={sent}
        sx={submitButtonStyle}
      >
        {sent ? "Working, please don't refresh..." : "Finish"}
      </Button>
    </Box>
  )
}

export default CheckoutForm
