import React from 'react'
import AppBar from '../components/AppBar'
import ProductHero from '../components/ProductHero'
import ProductValues from '../components/ProductValues'
import ProductPricing from '../components/ProductPricing'
import Footer from '../components/Footer'
import { AuthContext } from '../helpers/auth'

function Home() {
  const { user } = React.useContext(AuthContext)
  return <>
    <AppBar />
    <ProductHero />
    <ProductValues />
    {!user || !user.hasActiveSubscription ?
      <ProductPricing /> : null}
    <Footer />
  </>
}

export default Home
