import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import KeyIcon from '@mui/icons-material/Key'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
}

function ProductValues() {
  return (
    <div className = "infoSection">
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#fff' }}
      >
        <Container sx={{ mt: 15, mb: 15, display: 'flex', position: 'relative' }}>
          <Box
            component="img"
            src="https://mui.com/static/themes/onepirate/productCurvyLines.png"
            alt="curvy lines"
            sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
          />
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <KeyIcon className="icons" />
                <div className= "titles">
                  Pick your Magic Key
                </div>
                <div className="description-title">
                  Pick from any of the Magic Keys or ticket types
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <EventAvailableIcon className="icons" />
                <div className= "titles">
                  Choose Your Date(s)
                </div>
                <div className="description-title">
                  Choose the date(s) you are interested in going
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <AutoFixHighIcon className="icons" />
                <div className= "titles">
                  Get Notified!
                </div>
                <div className="description-title">
                  Receive a text alert when the date opens up!
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default ProductValues
