import React from 'react'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'


function CheckoutSuccess() {
  return <>
    <AppBar />

    <div className="page-container login-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Success!
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 2, fontSize: 16 }}>
          Your subscription to Magic Reservation has been activated! Click <Link to="/">here</Link> to start selecting your dates and getting notified.
        </Typography>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default CheckoutSuccess
