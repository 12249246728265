export const submitButtonStyle = {
  mt: 3,
  mb: 2 ,
  backgroundColor: '#07182f',
  color: '#fff',
  ':hover': {
    bgcolor: '#07182f',
    color: '#fff',
  },
  ':disabled': {
    color: '#fff',
  },
}
