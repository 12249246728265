import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'
import PassTypeField from '../components/PassTypeField'
import { AuthContext } from '../helpers/auth'
import { phone, required, parsePhoneNumber } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate(values) {
  const errors = required(['phone', 'passType'], values)

  if (!errors.phone) {
    const phoneError = phone(values.phone)
    if (phoneError) {
      errors.phone = phoneError
    }
  }

  return errors
}


function Setup() {
  const navigate = useNavigate()
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const { user, updateUser } = React.useContext(AuthContext)

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await updateUser({ phoneNumber: parsePhoneNumber(values.phone), passType: values.passType })
        navigate(user.hasActiveSubscription ? '/' : '/checkout')
      } catch (error) {
        setSent(false)
        if (error.code === "invalid-phone-number" || error.code === "duplicate-phone-number") {
          setErrors({ phone: error.message })
        }
      }
    }
  }

  return <>
    <AppBar />
      <div className="page-container setup-container">
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Let's get you set up!
          </Typography>

          <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 6 }}>
            <TextField
              required
              fullWidth
              name="phone"
              label="Phone Number"
              margin="normal"
              variant="standard"
              autoComplete="phone"
              disabled={sent}
              value={values.phone || ''}
              onChange={e => setValue('phone', e.target.value)}
              error={!!errors.phone}
              helperText={errors.phone}
            />

            <PassTypeField
              disabled={sent}
              value={values.passType}
              onChange={e => setValue('passType', e.target.value)}
              error={!!errors.passType}
              helperText={errors.passType} />

            <Button
              fullWidth
              type="submit"
              color="primary"
              disabled={sent}
              sx={submitButtonStyle}
            >
              Done
            </Button>
          </Box>
        </AppForm>
      </div>
    <Footer />
  </>
}

export default Setup
