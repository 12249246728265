import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { isEmpty, omit } from 'lodash'

import Modal from '../components/Modal'
import PassTypeField from '../components/PassTypeField'
import { AuthContext } from '../helpers/auth'
import { email, phone, required, parsePhoneNumber } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate(values) {
  const errors = required(['email', 'phone', 'passType'], values)

  if (!errors.email) {
    const emailError = email(values.email)
    if (emailError) {
      errors.email = emailError
    }
  }

  if (!errors.phone) {
    const phoneError = phone(values.phone)
    if (phoneError) {
      errors.phone = phoneError
    }
  }

  return errors
}


function ProfileAccountTab() {
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const { user, updateUser } = React.useContext(AuthContext)

  React.useEffect(() => {
    setValues({ email: user.email, phone: user.phoneNumber, passType: user.passType })
  }, [user])

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await updateUser({ email: values.email.trim(), phoneNumber: parsePhoneNumber(values.phone), passType: values.passType })
        setShowSuccessModal(true)
        setSent(false)
      } catch (error) {
        setSent(false)
        if (error.code === "invalid-email" || error.code === "duplicate-email") {
          setErrors({ email: error.message })
        } else if (error.code === "invalid-phone-number" || error.code === "duplicate-phone-number") {
          setErrors({ phone: error.message })
        }
      }
    }
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <TextField
        required
        fullWidth
        name="email"
        label="Email"
        margin="normal"
        variant="standard"
        autoComplete="email"
        disabled={sent}
        value={values.email || ''}
        onChange={e => setValue('email', e.target.value)}
        error={!!errors.email}
        helperText={errors.email}
      />

      <TextField
        required
        fullWidth
        name="phone"
        label="Phone Number"
        margin="normal"
        variant="standard"
        autoComplete="phone"
        disabled={sent}
        value={values.phone || ''}
        onChange={e => setValue('phone', e.target.value)}
        error={!!errors.phone}
        helperText={errors.phone}
      />

      <PassTypeField
        disabled={sent}
        value={values.passType}
        onChange={e => setValue('passType', e.target.value)}
        error={!!errors.passType}
        helperText={errors.passType} />

      <Button
        fullWidth
        type="submit"
        color="primary"
        disabled={sent}
        sx={submitButtonStyle}
      >
        {sent ? "Saving..." : "Save"}
      </Button>

      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onButtonClick={() => setShowSuccessModal(false)}
        buttonText="Close"
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Profile Updated
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          Your profile has been successfully updated.
        </div>
      </Modal>
    </Box>
  )
}

export default ProfileAccountTab
