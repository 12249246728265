import React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'

const seasonPassValues = ['dream-key', 'believe-key', 'enchant-key', 'imagine-key']
const singlePassValues = ['ticket', 'ph']


function PassTypeField({ disabled, value, onChange, error, helperText }) {
  const [hasSeasonPass, setHasSeasonPass] = React.useState(null)

  React.useEffect(() => {
    if (seasonPassValues.includes(value)) {
      setHasSeasonPass("yes")
    } else if (singlePassValues.includes(value)) {
      setHasSeasonPass("no")
    }
  }, [value])

  return <>
    <Typography variant="h5" gutterBottom marked="center" align="center" sx={{mt: 3}}>
      Are you a season pass holder?
    </Typography>
    <ToggleButtonGroup
      fullWidth
      variant="contained"
      value={hasSeasonPass}
      onChange={e => {
        setHasSeasonPass(e.target.value)
        onChange({ target: { value: null }})
      }}
    >
      <ToggleButton value="yes">Yes</ToggleButton>
      <ToggleButton value="no">No</ToggleButton>
    </ToggleButtonGroup>

    {hasSeasonPass === "yes" ?
      <>
        <Typography variant="h5" gutterBottom marked="center" align="center" sx={{mt: 3}}>
          Select your Magic Key
        </Typography>
        <ToggleButtonGroup
          fullWidth
          variant="contained"
          value={value}
          onChange={onChange}
        >
          <ToggleButton value="dream-key">Dream</ToggleButton>
          <ToggleButton value="believe-key">Believe</ToggleButton>
          <ToggleButton value="enchant-key">Enchant</ToggleButton>
          <ToggleButton value="imagine-key">Imagine</ToggleButton>
        </ToggleButtonGroup>
      </> :

     hasSeasonPass === "no" ?
      <>
        <Typography variant="h5" gutterBottom marked="center" align="center" sx={{mt: 3}}>
          Select your ticket type
        </Typography>
        <ToggleButtonGroup
          fullWidth
          variant="contained"
          value={value}
          onChange={onChange}
        >
          <ToggleButton value="ticket">One Park Per Day</ToggleButton>
          <ToggleButton value="ph">Park Hopper</ToggleButton>
        </ToggleButtonGroup>
      </> :

      null}

      {error ?
        <Typography variant="helperText" component="p" sx={{ textAlign: 'center', fontSize: 14, mt: 1 }}>
          {helperText}
        </Typography> : null}
  </>
}


export default PassTypeField
