import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import MuiAppBar from '@mui/material/AppBar'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'

import Firework from '../images/firework.png'
import { AuthContext } from '../helpers/auth'


function AppBar() {
  const [showDrawer, setShowDrawer] = React.useState(false)
  const { user, logout } = React.useContext(AuthContext)

  const hideDrawerAndLogout = () => {
    setShowDrawer(false)
    logout()
  }
  const hideDrawer = () => {
    setShowDrawer(false)
  }

  return <>
    <MuiAppBar elevation={0} position="fixed">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, position: 'absolute' }}
          onClick={() => setShowDrawer(!showDrawer)}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img className="fireworkLogo" src={Firework} alt="" />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </MuiAppBar>

    <Drawer
      anchor="left"
      sx={{'& .MuiDrawer-paper': { width: 300 }}}
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
    >
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          onClick={() => setShowDrawer(false)}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      <Divider />

      <List>
        <Link to="/" style={{ textDecoration: 'none', color: '#030303' }} onClick={hideDrawer}>
          <ListItem button>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <Link to="/FAQ" style={{ textDecoration: 'none', color: '#030303' }} onClick={hideDrawer}>
          <ListItem button>
            <ListItemText primary="FAQ" />
          </ListItem>
        </Link>
      </List>

      <Divider />

      {user ?
        <List>
          <Link to="/profile" style={{ textDecoration: 'none', color: '#030303' }} onClick={hideDrawer}>
            <ListItem button>
              <ListItemText primary="Profile" />
            </ListItem>
          </Link>
          <ListItem button style={{ color: '#030303' }} onClick={hideDrawerAndLogout}>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List> :

        <List>
          <Link to="/signup" style={{ textDecoration: 'none', color: '#030303' }} onClick={hideDrawer}>
            <ListItem button>
              <ListItemText primary="Sign Up" />
            </ListItem>
          </Link>
          <Link to="/login" style={{ textDecoration: 'none', color: '#030303' }} onClick={hideDrawer}>
            <ListItem button>
              <ListItemText primary="Log In" />
            </ListItem>
          </Link>
        </List>}
    </Drawer>
  </>
}

export default AppBar
