import React from 'react'
import Alert from '@mui/material/Alert'
import { Calendar } from 'react-multi-date-picker'
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useNavigate } from 'react-router-dom'

import Modal from './Modal'
import ProductHeroLayout from './ProductHeroLayout'
import { AuthContext } from '../helpers/auth'

const backgroundImage = 'https://i.imgur.com/gkaY4wu.jpg'

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

function format(date) {
  const [month, day] = date.split('-').slice(1)
  return `${monthNames[parseInt(month)-1]} ${parseInt(day)}`
}


export default function ProductHero() {
  const navigate = useNavigate()
  const [alertText, setAlertText] = React.useState(null)
  const [alertClass, setAlertClass] = React.useState('hidden')
  const [selectedDates, setSelectedDates] = React.useState(null)
  const [showSetupModal, setShowSetupModal] = React.useState(false)
  const [showSignUpModal, setShowSignUpModal] = React.useState(false)
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false)
  const [showDateLimitModal, setShowDateLimitModal] = React.useState(false)
  const { user, dates, addDate, removeDate } = React.useContext(AuthContext)

  // this will run everytime alertText changes
  React.useEffect(() => {
    // set timer ids to null to help with clean up - null is OK here
    let alertFadeOutTimer = null
    let alertHideTimer = null

    if (alertText) {
      setAlertClass("visible")
      alertFadeOutTimer = setTimeout(() => {
        setAlertClass("fade-out")
      }, 5000)
      alertHideTimer = setTimeout(() => {
        setAlertClass("hidden")
      }, 6500)
    } else {
      setAlertClass("hidden")
    }

    // return function helps to clean up timeouts if they are happening when component is removed from dom
    return () => {
      clearTimeout(alertFadeOutTimer)
      clearTimeout(alertHideTimer)
    }
  }, [
    alertText
  ])

  // Handle date update logic
  const setDates = newDateObjects => {
    const newDates = newDateObjects.map(x => x.format('YYYY-MM-DD'))
    if (!user) {
      setShowSignUpModal(true)
      setSelectedDates(newDates)
    } else if (!user.phoneNumber || !user.passType) {
      setShowSetupModal(true)
      setSelectedDates(newDates)
    } else if (!user.hasActiveSubscription) {
      setShowCheckoutModal(true)
      setSelectedDates(newDates)
    } else if (dates.length >= 7 && newDates.length > dates.length) {
      setShowDateLimitModal(true)
      setSelectedDates(newDates)
    } else {
      let newDates = newDateObjects.map(x => x.format('YYYY-MM-DD'))
      for (let date of newDates) {
        if (!dates.includes(date)) {
          setAlertText(`Great! We'll send you an alert when ${format(date)} becomes available.`)
          addDate(date)
        }
      }
      for (let date of dates) {
        if (!newDates.includes(date)) {
          setAlertText(`Removed ${format(date)}, you won't get any more alerts for this date.`)
          removeDate(date)
        }
      }
    }
  }

  const hideModals = () => {
    setSelectedDates(null)
    setShowSignUpModal(false)
    setShowCheckoutModal(false)
    setShowDateLimitModal(false)
  }

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#0f1826', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <div className="mainTitle">
        Choose Your Dates
      </div>

      <div>
        <Calendar
          multiple
          disableMonthPicker
          disableYearPicker
          plugins={user ? [
            <DatePanel sort="date" />
          ] : []}
          className="rmdp-mobile"
          minDate={new Date()}
          value={selectedDates || dates}
          onChange={setDates}
        />
        <Alert
          className={`calendar-alert ${alertClass || ''}`}
          severity={alertText && alertText.startsWith("Removed") ? "error" : "success"}
        >
          {alertText}
        </Alert>
      </div>

      <Modal
        buttonText="Sign Up"
        open={showSignUpModal}
        onClose={hideModals}
        onButtonClick={() => navigate('/signup')}
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Sign Up Today!
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          Sign up for $9.99/mo to get unlimited text alerts and secure your reservations to Disneyland!
        </div>
      </Modal>

      <Modal
        buttonText="Go to setup"
        open={showSetupModal}
        onClose={hideModals}
        onButtonClick={() => navigate('/setup')}
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Set up your account
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          Finish setting up your account to get unlimited text alerts and secure your reservations to Disneyland!
        </div>
      </Modal>

      <Modal
        buttonText="Go to checkout"
        open={showCheckoutModal}
        onClose={hideModals}
        onButtonClick={() => navigate('/checkout')}
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Activate Your Subscription
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          Activate your subscription for $9.99/mo to get unlimited text alerts and secure your reservations to Disneyland!
        </div>
      </Modal>

      <Modal
        buttonText="Close"
        open={showDateLimitModal}
        onClose={hideModals}
        onButtonClick={hideModals}
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Date Limit Reached
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          You can only select up to 7 days at once. To add this date, you must remove one of your other dates.
        </div>
      </Modal>
    </ProductHeroLayout>
  )
}
