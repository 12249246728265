import React from 'react'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'


function PasswordResetSuccess() {
  return <>
    <AppBar />

    <div className="page-container login-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Successfully Reset Password
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 2, fontSize: 16 }}>
          Your password has been successfully changed! Click <Link to="/login">here</Link> to log in.
        </Typography>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default PasswordResetSuccess
