import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { isEmpty, omit } from 'lodash'

import Modal from '../components/Modal'
import { updatePassword } from '../helpers/auth'
import { required } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate(values) {
  const errors = required(['oldPassword', 'newPassword', 'confirmPassword'], values)

  if (!errors.newPassword && !errors.confirmPassword && values.newPassword.trim() !== values.confirmPassword.trim()) {
    errors.confirmPassword = "Passwords don't match each other"
  }

  return errors
}


function ProfilePasswordTab() {
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await updatePassword(values.oldPassword.trim(), values.newPassword.trim())
        setShowSuccessModal(true)
        setSent(false)
        setValues({})
      } catch (error) {
        setSent(false)
        if (error.code === "wrong-password") {
          setErrors({ oldPassword: error.message })
        } else if (error.code === "invalid-password") {
          setErrors({ newPassword: error.message })
        }
      }
    }
  }

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <TextField
        required
        fullWidth
        name="old-password"
        label="Old Password"
        type="password"
        margin="normal"
        variant="standard"
        autoComplete="old-password"
        disabled={sent}
        value={values.oldPassword || ''}
        onChange={e => setValue('oldPassword', e.target.value)}
        error={!!errors.oldPassword}
        helperText={errors.oldPassword}
      />
      <TextField
        required
        fullWidth
        name="new-password"
        label="New Password"
        type="password"
        margin="normal"
        variant="standard"
        autoComplete="new-password"
        disabled={sent}
        value={values.newPassword || ''}
        onChange={e => setValue('newPassword', e.target.value)}
        error={!!errors.newPassword}
        helperText={errors.newPassword}
      />
      <TextField
        required
        fullWidth
        name="confirm-new-password"
        label="Confirm Password"
        type="password"
        margin="normal"
        variant="standard"
        autoComplete="confirm-new-password"
        disabled={sent}
        value={values.confirmPassword || ''}
        onChange={e => setValue('confirmPassword', e.target.value)}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />

      <Button
        fullWidth
        type="submit"
        color="primary"
        disabled={sent}
        sx={submitButtonStyle}
      >
        {sent ? "Updating..." : "Update Password"}
      </Button>

      <Modal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onButtonClick={() => setShowSuccessModal(false)}
        buttonText="Close"
      >
        <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
          Password Updated
        </div>
        <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
          Your password has been successfully changed.
        </div>
      </Modal>
    </Box>
  )
}

export default ProfilePasswordTab
