import React from 'react'
import AppBar from '../components/AppBar'
import Footer from '../components/Footer'


function Disclaimer() {
  return <>
    <AppBar />

    <div className = 'disclamerContainer'>
    <div className="disclaimerTitle">
        Disclaimer
    </div>
      <div className="disclaimerBody">
        <b>Last updated November 28. 2021</b>
        <br/><br/><br/>
        <b>INTRODUCTION</b>
        <br/><br/>
        The information provided by Magic Reservation (“we,” “us” or “our”) on MagicReservation.com (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
        <br/><br/>
        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on the Site is solely at your own risk.
        <br/><br/>
        This disclaimer was created using Termly.
        <br/><br/><br/>
        <b>EXTERNAL LINKS DISCLAIMER FOR WEBSITE</b>
        <br/><br/>
        The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
        <br/><br/>
        We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
        <br/><br/><br/>
        <b>TESTIMONIALS DISCLAIMER FOR WEBSITE</b>
        <br/><br/>
        The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.
        <br/><br/>
        The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.
        <br/><br/>
        The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.
        <br/><br/><br/>
        <b>NON-AFFILIATE DISCLAIMER</b>
        <br/><br/>
        Magic Reservation & MagicReservation.com are not affiliated in any way with the Walt Disney Company or any of its affiliates or subsidiaries.
        <br/><br/><br/>
        <b>CONTACT US</b>
        <br/><br/>
        If you have questions or comments about this Disclaimer, please contact us at:
        <br/>
        Magic Reservation
        <br/>
        support@magicreservation.com
        <br/><br/>

        This policy is effective as of November 28, 2021.
        <br/><br/>

      </div>
    </div>

    <Footer />
  </>
}

export default Disclaimer
