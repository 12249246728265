import React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'
import TabPanel from '../components/TabPanel'
import ProfileAccountTab from '../components/ProfileAccountTab'
import ProfilePasswordTab from '../components/ProfilePasswordTab'
import ProfileSubscriptionTab from '../components/ProfileSubscriptionTab'


function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function Profile() {
  const [tab, setTab] = React.useState(0)

  return <>
    <AppBar />

    <div className="page-container profile-container">
      <AppForm style={{ width: '600px' }}>
        <Typography variant="h4" marked="center" align="center">
          Profile
        </Typography>

        <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={(e, value) => setTab(value)} aria-label="tabs">
            <Tab label="Account" {...a11yProps(0)} />
            <Tab label="Password" {...a11yProps(1)} />
            <Tab label="Subscription" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={tab} index={0}>
          <ProfileAccountTab />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ProfilePasswordTab />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ProfileSubscriptionTab />
        </TabPanel>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default Profile
