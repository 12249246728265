import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

function AppForm(props) {
  return (
    <Box style={props.style}>
      <Container maxWidth="sm">
        <Box sx={{ mt: 7, mb: 12 }}>
          <Paper
            background="light"
            sx={{ py: { xs: 4, md: 8 }, px: { xs: 3, md: 6 } }}
          >
            {props.children}
          </Paper>
        </Box>
      </Container>
    </Box>
  )
}

export default AppForm
