import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiModal from '@mui/material/Modal'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const modalButtonStyle = {
  fontFamily: 'Roboto',
  textAlign: 'center',
  fontWeight: 300,
  backgroundColor: '#d72729',
  color: '#f3f3f3',
  minWidth: 200,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}


function Modal({ buttonText, open, onClose, onButtonClick, children }) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ border: 'none' }}
    >
      <Box sx={modalStyle}>
        {children}

        <Button onClick={onButtonClick} style={modalButtonStyle}>
          {buttonText}
        </Button>
      </Box>
    </MuiModal>
  )
}

export default Modal
