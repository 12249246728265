const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
const phoneNumberRegex = /^(?:\+|\+1)?[-\s.]?[(]?([0-9]{3})[)]?[-\s.]?([0-9]{3})[-\s.]?([0-9]{4})$/im

function isEmail(string) {
  return emailRegex.test(string)
}

export function email(value) {
  return value && !isEmail(value.trim()) ? 'Invalid email' : null
}

function isPhoneNumber(string) {
  return phoneNumberRegex.test(string)
}

export function phone(value) {
  return value && !isPhoneNumber(value.trim()) ? 'Invalid phone number' : null
}

export function parsePhoneNumber(value) {
  let [x, y, z] = value.trim().match(phoneNumberRegex).slice(1)
  return `${x}${y}${z}`
}

function isDirty(value) {
  return value || value === 0
}

export function required(requiredFields, values) {
  return requiredFields.reduce(
    (fields, field) => ({
      ...fields,
      ...(isDirty(values[field]) ? undefined : { [field]: 'Required' }),
    }),
    {},
  );
}
