import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'
import { resetPassword } from '../helpers/auth'
import { required } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate (values) {
  const errors = required(['newPassword', 'confirmPassword'], values)

  if (!errors.newPassword && !errors.confirmPassword && values.newPassword.trim() !== values.confirmPassword.trim()) {
    errors.confirmPassword = "Passwords don't match each other"
  }

  return errors
}


function ResetPassword() {
  const navigate = useNavigate()
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await resetPassword(values.newPassword)
        navigate('/password-reset-success')
      } catch (error) {
        setSent(false)
        if (error.code === "invalid-reset-token") {
          setErrors({ password: "This token isn't valid anymore, you'll have to go to the login page and request a new email."})
        } else if (error.code === "invalid-password") {
          setErrors({ password: error.message })
        } else {
          setErrors({ password: "An error occurred while resetting your password. Please contact support." })
        }
      }
    }
  }

  return <>
    <AppBar />

    <div className="page-container login-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Choose a new password
        </Typography>

        <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          required
          fullWidth
          name="new-password"
          label="New Password"
          type="password"
          margin="normal"
          variant="standard"
          autoComplete="new-password"
          disabled={sent}
          value={values.newPassword || ''}
          onChange={e => setValue('newPassword', e.target.value)}
          error={!!errors.newPassword}
          helperText={errors.newPassword}
        />
        <TextField
          required
          fullWidth
          name="confirm-new-password"
          label="Confirm Password"
          type="password"
          margin="normal"
          variant="standard"
          autoComplete="confirm-new-password"
          disabled={sent}
          value={values.confirmPassword || ''}
          onChange={e => setValue('confirmPassword', e.target.value)}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
          <Button
            fullWidth
            type="submit"
            disabled={sent}
            sx={submitButtonStyle}
          >
            {sent ? 'In progress…' : 'Reset Password'}
          </Button>
        </Box>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default ResetPassword
