import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'
import { AuthContext } from '../helpers/auth'
import { email, required } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate (values) {
  const errors = required(['email', 'password'], values)

  if (!errors.email) {
    const emailError = email(values.email)
    if (emailError) {
      errors.email = emailError
    }
  }

  return errors
}


function Login() {
  const navigate = useNavigate()
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const { login } = React.useContext(AuthContext)

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await login(values.email.trim(), values.password.trim())
        navigate('/')
      } catch (error) {
        setSent(false)
        if (error.code === "invalid-email-or-password") {
          setErrors({ email: error.message })
        }
      }
    }
  }

  return <>
    <AppBar />

    <div className="page-container login-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Log In
        </Typography>
        <Typography variant="body2" align="center">
          Not a member yet?
        </Typography>
        <Typography variant="body2" align="center">
          <Link to="/signup">
            Sign up here
          </Link>
        </Typography>

        <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            margin="normal"
            variant="standard"
            autoComplete="email"
            disabled={sent}
            value={values.email || ''}
            onChange={e => setValue('email', e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            margin="normal"
            variant="standard"
            autoComplete="password"
            disabled={sent}
            value={values.password || ''}
            onChange={e => setValue('password', e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Button
            fullWidth
            type="submit"
            disabled={sent}
            sx={submitButtonStyle}
          >
            {sent ? 'In progress…' : 'Log In'}
          </Button>
        </Box>

        <Typography variant="body2" align="center">
          <Link to="/forgot-password">
            Forgot password?
          </Link>
        </Typography>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default Login
