import React from 'react'
import Typography from '@mui/material/Typography'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import Footer from '../components/Footer'


function PasswordResetSent() {
  return <>
    <AppBar />

    <div className="page-container login-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Password Reset Sent
        </Typography>
        <Typography variant="body2" align="center" sx={{ mt: 2, fontSize: 16 }}>
          We've sent you an email with instructions on how to reset your password.
          If you don't receive the email within a minute,
          please make sure you entered the same email address that you signed up with,
          and be sure to check your spam folder!
        </Typography>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default PasswordResetSent
