import React from 'react'
import Typography from '@mui/material/Typography'
import { Elements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'
import AppForm from '../components/AppForm'
import CheckoutForm from '../components/CheckoutForm'
import { AuthContext } from '../helpers/auth'
import { Stripe } from '../helpers/stripe'


function Setup() {
  const navigate = useNavigate()
  const { subscribe } = React.useContext(AuthContext)

  const handleSubmit = async (values, cardElement) => {
    await subscribe(cardElement, values.subscriptionType, values.promoCode)
    navigate('/checkout-success')
  }

  return <>
    <AppBar />

    <div className="page-container checkout-container">
      <AppForm>
        <Typography variant="h4" gutterBottom marked="center" align="center">
          Choose Subscription
        </Typography>

        <Elements stripe={Stripe}>
          <CheckoutForm onSubmit={handleSubmit} />
        </Elements>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default Setup
