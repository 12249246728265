import React from 'react'
import AppBar from '../components/AppBar'
import Footer from '../components/Footer'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function FAQ() {
  return <>
    <AppBar />

    <div className="page-container FAQ-container">
      <div className="mainTitleFAQ">
        Frequently Asked Questions
      </div>

      <div className="FAQ-Accordion">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Magic Reservation?</Typography>
          </AccordionSummary>
          <AccordionDetails>
              <Typography>
              Magic Reservation makes your Disney park reservations easy! With all the booked dates, it has become increasingly difficult to find the date you want. Magic Reservation sends you an alert once your desired date has an open slot. All you have to do is book it!
              </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Do you make the reservations?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Magic Reservation only alerts you of an open reservation, we DO NOT make the reservation for you. You need to go to the Disney park website with your Disney account to make your reservation. 
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Do you sell tickets or Magic Keys?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Magic Reservation DOES NOT provide the sale of any Disney merchandise, including tickets and Magic Keys. You must go to Disney stores/websites for any merchandise, tickets, and Magic Keys. We do not verify if you own the selected Magic Key or ticket either.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How do I make a reservation?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Click the link from our text alerts or follow this link to the <a href= " https://disneyland.disney.go.com/entry-reservation/add/select-party/">Disney website</a>


            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How do you know when my date opens up?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Our robots are constantly checking the Disney reservation calendar for any potential openings.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How much do you charge?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            For a monthly subscription, you would only pay $9.99 plus tax. For a yearly subscription, you would only pay $99.99 plus tax.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How do you let me know about an open reservation slot?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Magic Reservation will send you a text alert once your selected date has an open slot.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Do you accept international phone numbers?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We do not support international numbers at this time.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>How many dates can I select at one time?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            You can select up to 7 days at a time with unlimited text alerts. Once a date passes or you choose to no longer receive alerts for that date, you can choose another date.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>
            What happens if I get my reservation? What happens if I do not get my reservation?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Each text message alert will also include an option to let us know if you booked your reservation or would like to stop receiving alerts for that date. Please follow the prompts in the text alert to reflect your reservation &amp; desired alert status.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>

    <Footer />
  </>
}

export default FAQ
