import React from 'react'
import Footer from '../components/Footer'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Link, useNavigate } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'

import AppBar from '../components/AppBar'
import AppForm from '../components/AppForm'
import { AuthContext } from '../helpers/auth'
import { email, required } from '../helpers/validation'
import { submitButtonStyle } from '../styles'

function validate(values) {
  const errors = required(['email', 'password', 'confirmPassword'], values)

  if (!errors.email) {
    const emailError = email(values.email)
    if (emailError) {
      errors.email = emailError
    }
  }

  if (!errors.password && !errors.confirmPassword && values.password.trim() !== values.confirmPassword.trim()) {
    errors.confirmPassword = "Passwords don't match each other"
  }

  return errors
}


function SignUp() {
  const navigate = useNavigate()
  const [sent, setSent] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const { signUp } = React.useContext(AuthContext)

  const setValue = (key, value) => {
    setValues({ ...values, [key]: value })
    setErrors(omit(errors, key))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let errors = validate(values)
    setErrors(errors)

    if (isEmpty(errors)) {
      setSent(true)
      try {
        await signUp(values.email.trim(), values.password.trim())
        navigate('/setup')
      } catch (error) {
        setSent(false)
        if (error.code === "invalid-email" || error.code === "duplicate-email") {
          setErrors({ email: error.message })
        } else if (error.code === "invalid-password") {
          setErrors({ password: error.message })
        }
      }
    }
  }

  return <>
    <AppBar />

    <div className="page-container signup-container">
      <AppForm>
        <Typography variant="h3" marked="center" align="center">
          Sign Up
        </Typography>
        <Typography variant="body2" align="center">
          <Link to="/login">
            Already have an account?
          </Link>
        </Typography>

        <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            margin="normal"
            variant="standard"
            autoComplete="email"
            disabled={sent}
            value={values.email || ''}
            onChange={e => setValue('email', e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            required
            fullWidth
            name="new-password"
            label="Password"
            type="password"
            margin="normal"
            variant="standard"
            autoComplete="new-password"
            disabled={sent}
            value={values.password || ''}
            onChange={e => setValue('password', e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            required
            fullWidth
            name="confirm-new-password"
            label="Confirm Password"
            type="password"
            margin="normal"
            variant="standard"
            autoComplete="confirm-new-password"
            disabled={sent}
            value={values.confirmPassword || ''}
            onChange={e => setValue('confirmPassword', e.target.value)}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />

          <Button
            fullWidth
            type="submit"
            color="primary"
            disabled={sent}
            sx={submitButtonStyle}
          >
            {sent ? 'Signing up…' : 'Sign Up'}
          </Button>
        </Box>
      </AppForm>
    </div>

    <Footer />
  </>
}

export default SignUp
