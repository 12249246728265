import { loadStripe } from '@stripe/stripe-js'
import { fetchWithAuth } from './auth'

export const STRIPE_PUBLIC_KEY = 'pk_live_M7ggFqMuLVdQx9HPhYNh1M84'

export const Stripe = loadStripe(STRIPE_PUBLIC_KEY)

const priceIds = {
  'annual': 'price_1K6W91EzOiKkMZJvDqmXVHkU',
  'monthly': 'price_1K6W97EzOiKkMZJvdimGjcFg'
}

// Custom error class
export class StripeError extends Error {
  constructor({ code, message }) {
    super()

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StripeError)
    }

    this.name = 'StripeError'
    this.code = code
    this.message = message
  }
}


// Subscription API methods
export async function createPaymentMethod(cardElement, subscriptionType, promoCode) {
  let stripe = await Stripe
  let result = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  })

  if (result.error) {
    throw new StripeError(result.error)
  } else {
    return await createSubscription(result.paymentMethod.id, subscriptionType, promoCode)
  }
}

export async function createSubscription(paymentMethodId, subscriptionType, promoCode) {
  let priceId = priceIds[subscriptionType]
  let result = await fetchWithAuth('/subscriptions/create', 'POST', { priceId, paymentMethodId, promoCode })

  return {
    paymentMethodId: paymentMethodId,
    priceId: priceId,
    subscription: result,
  }
}

export async function cancelSubscription() {
  return await fetchWithAuth('/subscriptions/cancel', 'POST')
}

export async function reactivateSubscription() {
  return await fetchWithAuth('/subscriptions/reactivate', 'POST')
}

export async function checkPromoCode(subscriptionType, promoCode) {
  let priceId = priceIds[subscriptionType]
  return await fetchWithAuth('/subscriptions/promo', 'POST', { priceId, promoCode })
}
