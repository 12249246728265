import React from 'react'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { useNavigate } from 'react-router-dom'

import Monthly from '../images/monthly.png'
import Yearly from '../images/yearly.png'


function ProductPricing() {
  const navigate = useNavigate()

  return <>
    <div className="pricingContainer">
      
      <div className="pricingTitle">
        Pricing
      </div>

      {/*<div className="subTitle-Pricing">
        Choose a plan → Get alerts → Go to Disneyland!
      </div>*/}

      <div className="pricingCards">
        <Card className="pricingCard">
          <CardContent>
            <img className="pricingImage" src={Yearly} alt="" />
            <div className="pricingTitleCards" sx={{ fontSize: 30 }} color="text.secondary">
              Annual Pass
            </div>
            <div className="pricingSubTitle" variant="h5" component="div" sx={{fontFamily: 'Roboto' }}>
              Ideal for passholders
            </div>
            <div className="pricingSubTitleAnnual" variant="h5" component="div">
              Save $19.89 a year!
            </div>
            <Typography className="pricingSubTitlePrice" variant="h5" component="div" sx={{ fontSize: 50 }}>
              $99.99
            </Typography>
            <Typography className="pricingSubSubTitle" variant="body2" sx={{fontFamily: 'Roboto' }}>
              per year
            </Typography>
          </CardContent>
          <CardActions>
            <Button fullWidth onClick={() => navigate('/signup')} size="small" sx={{ background: '#08182F', color: '#f3f3f3', textAlign: 'center', fontFamily: 'Roboto' }}>Get Started</Button>
          </CardActions>
        </Card>
        <Card className="pricingCard">
          <CardContent>
          <img className="pricingImage" src={Monthly} alt="" />
            <div className="pricingTitleCards" sx={{ fontSize: 30 }} color="text.secondary">
              Monthly Pass
            </div>
            <div className="pricingSubTitle"  sx={{fontFamily: 'Roboto' }}>
              Great for ticket holders
            </div>
            <div className="pricingSubTitleAnnual-none" variant="h5" component="div">
              .
            </div>
            <Typography className="pricingSubTitlePrice" variant="h5" component="div" sx={{ fontSize: 50 }}>
              $9.99
            </Typography>
            <Typography className="pricingSubSubTitle" variant="body2" sx={{fontFamily: 'Roboto' }}>
              per month
            </Typography>
          </CardContent>
          <CardActions>
            <Button fullWidth onClick={() => navigate('/signup')} size="small" sx={{ background: '#08182F', color: '#f3f3f3', textAlign: 'center', fontFamily: 'Roboto' }}>Get Started</Button>
          </CardActions>
        </Card>
      </div>

      <Button
        className="button-FAQ"
        variant="contained"
        onClick={() => navigate('/FAQ')} sx={{ fontFamily: 'Roboto' }}
      >
        Frequently Asked Questions
      </Button>
    </div>
  </>
}

export default ProductPricing
