import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const pricingButtonStyle = {
  borderRadius: '5px',
  borderColor: "#ccc",
  borderWidth: '2px',
  borderStyle: 'solid',
  width: '200px',
  cursor: 'pointer',
  color: '#707070'
}

const activePricingButtonStyle = {
  ...pricingButtonStyle,
  backgroundColor: '#ebebeb',
  borderColor: "#cecece",
  color: "#222"
}


function SubscriptionTypeField({ value, onChange, error, helperText }) {
  return <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className = "subscriptionPasses" style={value === 'annual' ? activePricingButtonStyle : pricingButtonStyle} onClick={() => onChange('annual')}>
        <div className="pricingTitleCards" style={{ fontSize: 18, paddingBottom: 0 }} color="text.secondary">
          Annual Pass
        </div>
        <Typography className="pricingSubTitlePrice" variant="h5" component="div" sx={{ fontSize: 30 }}>
          $99.99
        </Typography>
        <Typography className="pricingSubSubTitle" variant="body2" sx={{fontFamily: 'Roboto' }}>
          per year
        </Typography>
        <Typography variant="body2" align="center" sx={{ mb: 1 }}>
          Save $19.89 a year!
        </Typography>
      </div>

      <div className = "subscriptionPasses" style={value === 'monthly' ? activePricingButtonStyle : pricingButtonStyle} onClick={() => onChange('monthly')}>
        <div className="pricingTitleCards" style={{ fontSize: 18, paddingBottom: 0 }} color="text.secondary">
          Monthly Pass
        </div>
        <Typography className="pricingSubTitlePrice" variant="h5" component="div" sx={{ fontSize: 30 }}>
          $9.99
        </Typography>
        <Typography className="pricingSubSubTitle" variant="body2" sx={{fontFamily: 'Roboto' }}>
          per month
        </Typography>
      </div>
    </Box>

    {error ?
      <Typography variant="helperText" component="p" sx={{ textAlign: 'center', fontSize: 14, mt: 1 }}>
        {helperText}
      </Typography> : null}
  </>
}

export default SubscriptionTypeField
