import React from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import CircularProgress from '@mui/material/CircularProgress'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import theme from './helpers/theme'
import Home from './pages/Home'
import Setup from './pages/Setup'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import Profile from './pages/Profile'
import Checkout from './pages/Checkout'
import CheckoutSuccess from './pages/CheckoutSuccess'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import PasswordResetSent from './pages/PasswordResetSent'
import PasswordResetSuccess from './pages/PasswordResetSuccess'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CookiePolicy from './pages/CookiePolicy'
import Disclaimer from './pages/Disclaimer'
import FAQ from './pages/FAQ'

import Modal from './components/Modal'
import ScrollWrapper from './components/ScrollWrapper'
import * as Auth from './helpers/auth'
import * as Subscription from './helpers/stripe'

import 'url-search-params-polyfill'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import './styles.css'


function App() {
  const [showLogoutModal, setShowLogoutModal] = React.useState(false)
  const [initialized, setInitialized] = React.useState(false)
  const [user, setUser] = React.useState(null)
  const [dates, setDates] = React.useState([])

  React.useEffect(() => {
    loadUser()
  }, [])

  async function loadUser() {
    let user = await Auth.loadUser()
    if (user) {
      let dates = await Auth.loadDates()
      setInitialized(true)
      setUser(user)
      setDates(dates)
    } else {
      setInitialized(true)
    }
  }

  async function updateUser(data) {
    await Auth.updateUser(data)
    setUser({ ...user, ...data })
  }

  async function signUp(email, password) {
    let user = await Auth.signUp(email, password)
    setUser(user)
  }

  async function login(email, password) {
    let user = await Auth.login(email, password)
    let dates = await Auth.loadDates()
    setUser(user)
    setDates(dates)
  }

  async function logout() {
    await Auth.logout()
    setUser(null)
    setDates([])
    setShowLogoutModal(true)
  }

  async function subscribe(cardElement, subscriptionType, promoCode) {
    await Subscription.createPaymentMethod(cardElement, subscriptionType, promoCode)
    setUser({ ...user, hasActiveSubscription: true })
  }

  async function unsubscribe() {
    let result = await Subscription.cancelSubscription()
    setUser({ ...user, subscriptionEndDate: result.subscriptionEndDate })
  }

  async function resubscribe() {
    await Subscription.reactivateSubscription()
    setUser({ ...user, subscriptionEndDate: null })
  }

  async function addDate(date) {
    setDates([...dates, date])
    await Auth.addDate({ date, passType: user.passType })
  }

  async function removeDate(date) {
    setDates(dates.filter(x => x !== date))
    await Auth.removeDate(date)
  }

  return (
    <Auth.AuthContext.Provider value={{ user, dates, updateUser, signUp, login, logout, subscribe, unsubscribe, resubscribe, addDate, removeDate }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Modal
          buttonText="Close"
          open={showLogoutModal}
          onClose={() => setShowLogoutModal(false)}
          onButtonClick={() => setShowLogoutModal(false)}
        >
          <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
            Logged Out
          </div>
          <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '30px' }}>
            You've been successfully logged out.
          </div>
        </Modal>

        <Router>
          <ScrollWrapper>
            {!initialized ?
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <p style={{marginBottom: '20px'}}>✨ Loading the Magic, one moment ✨</p>
                <CircularProgress />
                <div style={{height:"200px"}} />
              </Box> :

             user ?
              <Routes>
                <Route path="/setup" element={<Setup />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/checkout-success" element={<CheckoutSuccess />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/CookiePolicy" element={<CookiePolicy />} />
                <Route path="/Disclaimer" element={<Disclaimer />} />
                <Route path="/*" element={<Home />} />
              </Routes> :

              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/password-reset-sent" element={<PasswordResetSent />} />
                <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/CookiePolicy" element={<CookiePolicy />} />
                <Route path="/Disclaimer" element={<Disclaimer />} />
                <Route path="/*" element={<Home />} />
              </Routes>}
          </ScrollWrapper>
        </Router>
      </ThemeProvider>
    </Auth.AuthContext.Provider>
  )
}

export default App
