import React from 'react'
import Cookies from 'js-cookie'
import { omit } from 'lodash'

const API_URL = 'https://api.magicreservation.com'


// Custom error class
export class APIError extends Error {
  constructor(code, message, ...params) {
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError)
    }

    this.name = 'APIError'
    this.code = code
    this.message = message
  }
}

// Helper function to load data from server with authentication and handle errors
export async function fetchWithAuth(url, method, data) {
  let token = Cookies.get('accessToken')
  let options = {
    method,
    headers: {'Content-Type': 'application/json', 'Access-Token': token},
    body: data ? JSON.stringify(data) : undefined
  }

  let response = await fetch(`${API_URL}${url}`, options)
  let responseData = await response.json()
  if (response.status !== 200 || responseData.result === "error") {
    throw new APIError(responseData.code, responseData.message)
  } else {
    return responseData
  }
}


// User API methods
export async function loadUser() {
  try {
    return await fetchWithAuth('/user', 'GET')
  } catch {
    return null
  }
}

export async function updateUser(data) {
  return await fetchWithAuth('/user', 'POST', data)
}

export async function signUp(email, password) {
  let response = await fetchWithAuth('/signup', 'POST', { email, password })
  Cookies.set('accessToken', response.accessToken)
  return omit(response, 'accessToken')
}

export async function login(email, password) {
  let response = await fetchWithAuth('/login', 'POST', { email, password })
  Cookies.set('accessToken', response.accessToken)
  return omit(response, 'accessToken')
}

export async function logout() {
  Cookies.remove('accessToken')
}

export async function updatePassword(oldPassword, newPassword) {
  return await fetchWithAuth('/update-password', 'POST', { oldPassword, newPassword })
}

export async function forgotPassword(email) {
  return await fetchWithAuth('/forgot-password', 'POST', { email })
}

export async function resetPassword(password) {
  let urlParams = new URLSearchParams(window.location.search)
  return await fetchWithAuth('/reset-password', 'POST', { newPassword: password, resetToken: urlParams.get("reset-token") })
}


// Date API methods
export async function loadDates() {
  return await fetchWithAuth('/dates', 'GET')
}

export async function addDate(date) {
  return await fetchWithAuth('/dates', 'POST', date)
}

export async function removeDate(date) {
  return await fetchWithAuth('/dates', 'DELETE', { date })
}


// Auth context
export const AuthContext = React.createContext(null)
