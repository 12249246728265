import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Elements } from '@stripe/react-stripe-js'

import Modal from '../components/Modal'
import CheckoutForm from '../components/CheckoutForm'
import { AuthContext } from '../helpers/auth'
import { Stripe } from '../helpers/stripe'
import { submitButtonStyle } from '../styles'


function ProfileSubscriptionTab() {
  const [sent, setSent] = React.useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = React.useState(false)
  const [showResubscribeModal, setShowResubscribeModal] = React.useState(false)
  const { user, subscribe, unsubscribe, resubscribe } = React.useContext(AuthContext)

  const handleSubscribe = async (values, cardElement) => {
    await subscribe(cardElement, values.subscriptionType, values.promoCode)
  }

  const handleUnsubscribe = async e => {
    setSent(true)

    try {
      await unsubscribe()
      setSent(false)
      setShowUnsubscribeModal(false)
    } catch (error) {
      setSent(false)
    }
  }

  const handleResubscribe = async e => {
    setSent(true)

    try {
      await resubscribe()
      setSent(false)
      setShowResubscribeModal(false)
    } catch (error) {
      setSent(false)
    }
  }

  return (
    <Box>
      <div className = "subscriptionStatus" style={{textAlign: 'center'}}>
        Subscription Status: <strong>{user.hasActiveSubscription ? "Active" : "Inactive"}</strong>
      </div>

      {!user.hasActiveSubscription ?
        <Elements stripe={Stripe}>
          <CheckoutForm sent={sent} onSubmit={handleSubscribe} />
        </Elements> :

       user.subscriptionEndDate && user.subscriptionEndDate > Date.now() / 1000 ?
        <Button
          fullWidth
          color="primary"
          disabled={sent}
          sx={submitButtonStyle}
          onClick={() => setShowResubscribeModal(true)}
        >
          Resubscribe
        </Button> :

         <Button
          fullWidth
          color="primary"
          disabled={sent}
          sx={submitButtonStyle}
          onClick={() => setShowUnsubscribeModal(true)}
        >
          Unsubscribe
        </Button>}

        <Modal
          open={showUnsubscribeModal}
          onClose={() => setShowUnsubscribeModal(false)}
          onButtonClick={handleUnsubscribe}
          buttonText={sent ? "Unsubscribing..." : "Unsubscribe"}
        >
          <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
            Are you sure you want to unsubscribe?
          </div>
          <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
            You will continue to receive Magic Reservation alerts until the end of your billing cycle.
          </div>
        </Modal>

        <Modal
          open={showResubscribeModal}
          onClose={() => setShowResubscribeModal(false)}
          onButtonClick={handleResubscribe}
          buttonText={sent ? "Resubscribing..." : "Resubscribe"}
        >
          <div id="signup-modal-title" style={{fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', paddingBottom: '30px'}}>
            Are you sure you want to resubscribe?
          </div>
          <div id="modal-modal-description" style={{ fontFamily: 'Roboto', textAlign: 'center', fontWeight: 300, paddingBottom: '20px' }}>
            You won't be charged again until the next billing cycle.
          </div>
        </Modal>
    </Box>
  )
}

export default ProfileSubscriptionTab
